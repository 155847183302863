:local .svgBrickRegion:hover {
  fill: #8f8f8f;
}


:local .brickRegionInfo {
  display: none;
}


.brickRegionInfoContainer {
  border: 1px solid #ccc !important;
  border-radius: 8px;
  flex: 1;
  padding: 1em;

}

:local .brickInfoIndicator {
  min-width: 15em;
  display: block;
  background-color: white;
  padding: 6px 6px;
  border-radius: 5px;
  box-shadow: 2px 2px 30px lightgrey;
  position: fixed;
  transform-origin: center;
  z-index: 2;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.2s, opacity 0.2s, position 0.2s;
}

:local .brickInfoIndicator.show {
  opacity: 1;
  transform: scale(1);
  transform-origin: top left;
}

:local .hoverBrickInfoElem {

  margin: 0px
}