@import "../app.scss";


:local .vessel3DTooltip {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100px;
    text-align: center;
    color:white;
    background: black;
    display: none;
    opacity: 0;
    transition: opacity 0.25s linear;
    border-radius: 20px;
    padding: 6px 15px;
    -webkit-box-shadow: 0 0 15px #dcdcdc;
            box-shadow: 0 0 15px #dcdcdc;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }