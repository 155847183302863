:local .sliceContainer {
  border: 1px solid #ccc !important;
  border-radius: 16px;
  flex: 1;
  padding: 1em;

}

:local .sliceHeightIndicator {
  padding: 0px;
  /**/
}


:local .verticalSlice {

  pointer-events: none;

}

:local .sliceSpinner {
  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
}

:local .sliceHeightIndicatorText {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}