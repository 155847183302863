$primary: #003262;
$secondary: #748892;
$danger: #ff4136;
$info: #D8DDE1;



$font-family-sans-serif: Roboto,
-apple-system,
system-ui,
BlinkMacSystemFont,
"Segoe UI",
"Helvetica Neue",
Arial,
sans-serif;
$font-family-serif: Georgia,
"Times New Roman",
Times,
serif;
$font-family-monospace: Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace;
$font-family-base: $font-family-sans-serif;


$enable-caret: false;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-print-styles: true;
$enable-validation-icons: true;


@import "~bootstrap/scss/bootstrap";

.btn-secondary {
  color: #fff;
}

.btn-secondary:hover {
  color: #fff;
}


.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}