:local .heatmapThicknessIndicator {
  min-width: 15em;
  display: block;
  background-color: white;
  padding: 6px 6px;
  border-radius: 5px;
  box-shadow: 2px 2px 30px lightgrey;
  position: fixed;
  transform-origin: center;
  z-index: 2;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.2s, opacity 0.2s, position 0.2s;
}

:local .heatmapThicknessIndicator.show {
  opacity: 1;
  transform: scale(1);
  transform-origin: top left;
}

:local .hoverInfoElem {

  margin: 0px
}

:local .heatmapContainer {
  width: 100%;
  height: 25%;
  position: relative;
}

:local .heatmapOverlay {
  position: absolute;
  top: 0;
  left: 0;
}

:local .svgGridLineX {
  stroke: #aaa;
  stroke-width: 0.1;
}


:local .svgGridLineY {
  stroke: #aaa;
  stroke-width: 0.1;
}

:local .svgGridValueLabel {
  fill: #EEE;
  alignment-baseline: middle;
  text-anchor: middle;
  font-size: 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:local .svgGridTickLabelX{
  font-size: 2px;
  alignment-baseline: baseline;
  text-anchor: middle;
}

:local .svgGridTickLabelY {
  font-size: 2px;
  alignment-baseline: middle;
  text-anchor: start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:local .svgAxisLabelX, :local .svgAxisLabelY {
  font-size: 0.1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


:local .heatmapSpinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
}

:local .heatmapLoading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;

  /* left, top and position just for the demo! */
  position: absolute;
  left: 50%;
  top: 50%;
}

@-webkit-keyframes rotate {

  /* 100% keyframe for  clockwise.
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}