:local .heatmapThicknessIndicator {
  min-width: 15em;
  display: block;
  background-color: white;
  padding: 6px 6px;
  border-radius: 5px;
  box-shadow: 2px 2px 30px lightgrey;
  position: fixed;
  transform-origin: center;
  z-index: 2;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.2s, opacity 0.2s, position 0.2s;
}

:local .heatmapThicknessIndicator.show {
  opacity: 1;
  transform: scale(1);
  transform-origin: top left;
}

:local .hoverInfoElem {

  margin: 0px
}

:local .heatmapContainer {
  width: 100%;
  height: 25%;
  position: relative;
}


:local .heatmapSpinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
}

:local .heatmapLoading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;

  /* left, top and position just for the demo! */
  position: absolute;
  left: 50%;
  top: 50%;
}

@-webkit-keyframes rotate {

  /* 100% keyframe for  clockwise.
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}