:local .sliceContainer {
  border: 1px solid #ccc !important;
  border-radius: 8px;
  flex: 1;
  padding: 1em;

}

:local .sliceAngleIndicator, :local  #fixedAngles > line {
  stroke: black ;
  stroke-width: 0.02;
  /**/
}


:local .circularSlice, .innerRadius,  .acceptableRadius, .originalThicknessRing{

  pointer-events: none;

}

:local #innerRadius{

  stroke: black;
  stroke-width: 0.02;
  fill:red;
  
}

:local #originalThicknessRing{

  stroke: #002700;
  stroke-width: 0.02;
  fill:none;
  
}




:local .sliceAngleIndicatorText,  :local #fixedAngles > text{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.2px;
  color: #222;
  /*text-anchor: middle;*/
}

:local #sliceDot > circle {
  fill: #424B87
}